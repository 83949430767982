<template>
    <div class="contain">
        <div class="contain-table">
            <el-table
                    :data="tableData"
                    :header-cell-style="{ background:'#b14040',color:'#fff'}"
                    border
                    style="width: 100%">
                <el-table-column label="排名" align="center">
                    <template slot-scope="scope">
                        <span style="color: #b14040">{{scope.$index + 1}}</span>
                    </template>
                </el-table-column>
                <el-table-column label="参赛者" align="center">
                    <template slot-scope="scope">
                        <span style="color: #b14040">{{scope.row.name}}</span>
                    </template>
                </el-table-column>
                <el-table-column label="照片" align="center">
                    <template slot-scope="scope">
                        <img :src="scope.row.avatarUrl"
                             style="width: 50px;height: 50px;border-radius: 50%"
                             alt="">
                    </template>
                </el-table-column>
                <el-table-column label="得分" show-overflow-tooltip align="center">
                    <template slot-scope="scope">
                        <span style="color: #b14040">{{scope.row.finalScore}}</span>
                    </template>
                </el-table-column>
            </el-table>
        </div>
        <div class="contain-btn">
            <span style="margin-right: 50px;font-size: 20px;color: #333;font-weight: bold">大屏控制</span>
            <el-radio-group v-model="radio" size="small" @change="changeEndEventStatus">
                <el-radio-button v-for="item in cityOptions" :label="item.id" :key="item.id"
                                 style="margin: 0 20px;box-sizing: border-box">{{item.name}}
                </el-radio-button>
            </el-radio-group>
        </div>
    </div>
</template>

<script>
    import {findById, changeEndEventStatus,getRanking} from "../../api/event";

    export default {
        name: "drawControl",
        data() {
            return {
                tableData: [],
                radio: 1,
                cityOptions: [
                    {
                        id: 1,
                        name: '排名信息',
                    },
                    {
                        id: 2,
                        name: '比赛封面',
                    }

                ],
                eventInfo: {}
            }
        },
        created() {
            this.eventInfo = JSON.parse(sessionStorage.getItem('event'))
            this.getEventInfo(this.eventInfo.id)
            this.getRanking(this.eventInfo.id)
        },
        methods: {
            async getRanking(id){
                const{data} = await getRanking(id)
                if (data.code===200){
                    this.tableData=data.data
                }
            },
            async changeEndEventStatus() {
                let obj = {
                    eventId: this.eventInfo.id,
                    type: this.radio
                }
                const {data} = await changeEndEventStatus(obj)
                if (data.code === 200) {
                    await this.getEventInfo(this.eventInfo.id)
                    this.$message.success('操作成功')
                }
            },
            //查询比赛信息
            async getEventInfo(id) {
                const {data} = await findById(id);
                if (data.code === 200) {
                    this.eventInfo = data.data
                    this.radio = this.eventInfo.endShow
                    if (this.roleFlag === '3') {
                        if (this.eventInfo.startStatus === 2) {
                            this.$router.push('/drawControl')
                        }
                        if (this.eventInfo.startStatus === 1) {
                            this.$router.push('/hostPage')
                        }
                        if (this.eventInfo.startStatus === 3) {
                            this.$router.push('/eventControl')
                        }
                        if (this.eventInfo.startStatus === 4) {
                            this.$router.push('/eventOver')
                        }
                    }
                    if (this.roleFlag === '4') {
                        if (this.eventInfo.startStatus === 1) {
                            this.$router.push('/beforeShow')
                        }
                        if (this.eventInfo.startStatus === 2) {
                            this.$router.push('/drawSort')
                        }
                        if (this.eventInfo.startStatus === 3) {
                            this.$router.push('/eventShow')
                        }
                        if (this.eventInfo.startStatus === 4) {
                            this.$router.push('/showOver')
                        }
                    }

                }
            }
        }
    }
</script>

<style scoped lang="less">
    .contain {
        position: relative;
        margin: 0px;
        width: 100%;
        height: 100%;
        background: url("../../assets/images/hostBanner.png") no-repeat;
        background-size: 100% 100%;
        background-attachment: fixed;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;

        .contain-table {
            width: 60%;
        }

        .contain-btn {
            margin-top: 50px;
            display: flex;
            align-items: center;
            justify-content: center;

            /deep/ .el-radio-button__orig-radio:checked + .el-radio-button__inner {
                background-color: #b24040;
                border-color: #b24040;
            }
        }
    }
</style>
